var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('assistant-header',{attrs:{"started":_vm.assistantStarted}}),_c('div',{staticClass:"gc-px-8 gc-py-4"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.assistantStarted)?_c('div',{key:"start-screen"},[_c('restore-alert',{attrs:{"hasPersistedState":_vm.machine.hasPersistedState.value},on:{"restore":function($event){_vm.machine.restoreState(),
              (_vm.assistantStarted = true),
              _vm.restoreCompletedSteps()}}}),_c('start-screen',{on:{"start":function($event){_vm.assistantStarted = true}}})],1):_c('div',{key:"steps"},[_vm._l((_vm.overviewSteps),function(step,i){return _c('div',{key:step},[_c('div',{staticClass:"gc-flex gc-items-center gc-mt-2 gc-mb-2",class:{
              'enable-click gc-cursor-pointer':
                _vm.completedSteps[step] || i < _vm.currentStepIndex - 1,
              'gc-text-primary':
                _vm.completedSteps[step] && i > _vm.currentStepIndex - 1,
            },on:{"click":function($event){$event.preventDefault();return _vm.goToStep(i, _vm.currentStepIndex, step)}}},[_c('i',{staticClass:"gc-flex gc-items-center gc-justify-center gc-w-6 gc-h-6 gc-rounded-full gc-text-xs gc-not-italic  gc-mr-2",class:[
                _vm.currentStepIndex === i + 1
                  ? 'gc-bg-primary gc-text-white gc-current-step'
                  : 'gc-bg-gray-100' ]},[(_vm.currentStepIndex <= i + 1)?_c('span',[_vm._v(_vm._s(i + 1))]):_c('font-awesome-icon',{staticClass:"gc-text-primary",attrs:{"icon":['fas', 'check']}})],1),_c('span',{class:{
                'gc-font-bold': _vm.currentStepIndex === i + 1,
                'gc-text-gray-500': _vm.currentStepIndex < i + 1,
                'gc-text-primary':
                  _vm.completedSteps[step] && i > _vm.currentStepIndex - 1,
              }},[_vm._v(" "+_vm._s(_vm.$t((step + ".title")))+" ")])]),_c('div',{staticClass:"step-wrap"},[(_vm.machine.currentStep.value.id == step)?_c('transition',{attrs:{"name":_vm.stepTransition,"mode":"out-in"}},[_c(_vm.machine.currentStep.value.component,{tag:"component",attrs:{"assistantContext":_vm.machine.context,"step":_vm.machine.currentStep.value,"steps":_vm.steps},on:{"transition":_vm.onTransition}})],1):_vm._e()],1)])}),_c('transition',{attrs:{"name":"fade"}},[(false)?_c('skip-message',{on:{"skip":function($event){return _vm.jumpToContactForm()}}}):_vm._e()],1)],2)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }