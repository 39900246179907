



















































































































































import { defineComponent } from '@vue/composition-api';
import { stepComponentProps } from '../../lib/steps/helper';
import { useBaseTransitions } from '../../lib/xstate/transitions';
import { getStepContext } from '../../lib/context';
import AssistantStep from '../../components/AssistantStep.vue';
import AssistantStepNavigation from '../../components/AssistantStepNavigation.vue';
import GcForm from '../../components/primitives/GcForm.vue';
import FormField from '../../components/FormField.vue';
import { StepDefinition } from '../../interfaces/step-definition';
import GcTextField from '../../components/primitives/GcTextField.vue';
import GcCheckbox from '../../components/primitives/GcCheckbox.vue';
import GcCombobox from '../../components/primitives/GcCombobox.vue';
import { StepId } from '../../consts/assistant-steps';
import completedSteps from '../../lib/completed-steps';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcCheckbox,
    GcCombobox,
    GcForm,
    GcTextField,
  },
  props: {
    ...stepComponentProps,
  },
  setup(props, { emit }) {
    const stepId = StepId.CONTACT_DETAILS;
    const { onNext, onPrev } = useBaseTransitions(emit);
    const formData = getStepContext(
      props.assistantContext,
      props.step as StepDefinition,
    );

    props.assistantContext[stepId].isEstateAddressSame = true;

    const checkRequired = value => {
      return !!value || 'Dies ist ein Plichtfeld';
    };

    return {
      checkRequired,
      completedSteps,
      formData,
      onNext,
      onPrev,
    };
  },
});
