































import { defineComponent } from '@vue/composition-api';
import GcImage from '../components/primitives/GcImage.vue';
import { efzkProdData } from '../config/products';

export default defineComponent({
  components: {
    GcImage,
  },
  props: {
    started: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    const baseUrl = process.env.VUE_APP_BASE_URL;
    return {
      baseUrl,
      efzkProdData,
    };
  },
});
