import {
  getFormattedFieldValue,
  getStepTitle,
} from '../../lib/formatter/context';
import stepDefs from '../../config/steps';
import { AssistantContext } from '../../config/context';
import { ref } from '@vue/composition-api';
import { sendMail } from '../../lib/submit/send-mail';

export function getOverviewItems(context: AssistantContext) {
  return stepDefs.slice(0, -2).map(step => {
    const title = getStepTitle(step.id);
    const value = step.fields?.reduce((acc, field) => {
      return (
        acc +
        `<div>${getFormattedFieldValue(field, context[step.id], step)}</div>`
      );
    }, '');
    return {
      id: step.id,
      title,
      value,
    };
  });
}

export function useDataSending() {
  const isSending = ref(false);
  const sentSuccessfully = ref(false);
  const error = ref<string | boolean>(false);
  const send = async (overviewItems, context) => {
    try {
      isSending.value = true;
      await sendMail(overviewItems, context)
      sentSuccessfully.value = true;
    } catch (err) {
      error.value = true;
      throw new Error(err);
    } finally {
      isSending.value = false;
    }
  };
  return {
    error,
    isSending,
    send,
    sentSuccessfully,
  };
}
