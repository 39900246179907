export enum StepId {
  NUMBER = 'number',
  ESTATE = 'estate',
  HEATING_SYSTEM = 'heatingSystem',
  HOT_WATER = 'hotWater',
  AIR_CONDITIONING = 'airConditioning',
  MODERNIZATION = 'modernization',
  ENERGY_SOURCE = 'energySource',
  CONTACT_DETAILS = 'contactDetails',
  OVERVIEW = 'overview',
  DONE = 'done',
}

export enum StepGroupId {
  LOCATIONS = 'locations',
}
