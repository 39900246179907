





















































































































import { computed, defineComponent } from '@vue/composition-api';
import AssistantStep from '../../components/AssistantStep.vue';
import GcForm from '../../components/primitives/GcForm.vue';
import AssistantStepNavigation from '../../components/AssistantStepNavigation.vue';
import MultipleChoice from '../../components/MultipleChoice.vue';
import { getStepContext } from '../../lib/context';
import { stepComponentProps } from '../../lib/steps/helper';
import GcSlider from '../../components/primitives/GcSlider.vue';
import GcTextField from '../../components/primitives/GcTextField.vue';
import FormField from '../../components/FormField.vue';
import { useBaseTransitions } from '../../lib/xstate/transitions';
import GcDropzone from '../../components/primitives/GcDropzone.vue';
import GcAlert from '../../components/primitives/GcAlert.vue';
import GcTextArea from '../../components/primitives/GcTextArea.vue';
import { StepId } from '../../consts/assistant-steps';
import completedSteps from '../../lib/completed-steps';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcAlert,
    GcDropzone,
    GcForm,
    GcSlider,
    GcTextArea,
    GcTextField,
    MultipleChoice,
  },
  props: {
    ...stepComponentProps,
  },
  setup(props, { emit }) {
    const stepId = StepId.MODERNIZATION;
    const { onNext, onPrev } = useBaseTransitions(emit);
    const formData = getStepContext(props.assistantContext, props.step!);

    const otherModernizations = computed({
      get() {
        return props.assistantContext[stepId].otherModernizations;
      },
      set(val) {
        props.assistantContext[stepId].otherModernizations = val;
      },
    });

    const checkNumber = (v: any) =>
      /^[0-9]+$/.test(v) || !v || `Nur nummerische Werte sind erlaubt`;

    return {
      checkNumber,
      completedSteps,
      formData,
      onNext,
      onPrev,
      otherModernizations,
    };
  },
});
