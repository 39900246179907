import { computed, ref } from '@vue/composition-api';

type EfzkProd = '1' | '2' | '3' | '4';

const PROD_ATTR = 'data-assistant-config';
export const PROD_CONFIG = {
  '1': {
    desc: `Um Ihre Beratung so effizient wie möglich zu gestalten, wären wir Ihnen sehr verbunden, wenn Sie uns vorab einige Informationen zur Verfügung stellen.`,
    title: 'iSFP-Assistent',
  },
  '2': {
    desc: `Um Ihre Berechnung so effizient wie möglich zu gestalten, wären wir Ihnen sehr verbunden, wenn Sie uns vorab einige Informationen zur Verfügung stellen.`,
    title: 'Wärmebedarfsberechnung-Assistent',
  },
  '3': {
    desc: `Um Ihre Beratung so effizient wie möglich zu gestalten, wären wir Ihnen sehr verbunden, wenn Sie uns vorab einige Informationen zur Verfügung stellen.`,
    title: 'Initialberatung-Assistent',
  },
  '4': {
    desc: `Um Ihre Beratung so effizient wie möglich zu gestalten, wären wir Ihnen sehr verbunden, wenn Sie uns vorab einige Informationen zur Verfügung stellen.`,
    title: 'KfW-Antrag-Assistent',
  },
};

export const efzkProd = ref<EfzkProd | null>(null);
export const efzkProdData = computed(() => {
  return efzkProd.value ? PROD_CONFIG[efzkProd.value] : { desc: '', title: '' };
});

export function defineEfzkProdByAttr() {
  const product = document
    ?.querySelector(`[${PROD_ATTR}]`)
    ?.getAttribute(`${PROD_ATTR}`);
  if (product) {
    efzkProd.value = product as EfzkProd;
  }
}
