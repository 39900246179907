import number from '../steps/number';
import estate from '../steps/estate';
import heatingSystem from '../steps/heating-system';
import hotWater from '../steps/hot-water';
import airConditioning from '../steps/air-conditioning';
import modernization from '../steps/modernization';
import energySource from '../steps/energy-source';
import contactDetails from '../steps/contact-details';
import overview from '../steps/overview';
import done from '../steps/done';

const steps = [
  number,
  estate,
  heatingSystem,
  hotWater,
  airConditioning,
  modernization,
  energySource,
  contactDetails,
  overview,
  done,
];

export default steps;
