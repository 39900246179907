import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import { StepDefinition } from '../../interfaces/step-definition';
import component from './Modernization.vue';
import { ValidationType } from '../../consts/validation-types';

const config: StepDefinition = {
  component,
  fields: [
    {
      name: 'windowInsulationYear',
      required: false,
      type: FormFieldType.TextField,
    },

    {
      name: 'upperFloorInsulationYear',
      required: false,
      type: FormFieldType.TextField,
    },
    {
      name: 'upperFloorInsulationThickness',
      required: false,
      type: FormFieldType.TextField,
    },

    {
      name: 'basementСeilingInsulationYear',
      required: false,
      type: FormFieldType.TextField,
    },
    {
      name: 'basementСeilingInsulationThickness',
      required: false,
      type: FormFieldType.TextField,
    },

    {
      name: 'insulationOfWallsOutsideYear',
      required: false,
      type: FormFieldType.TextField,
    },
    {
      name: 'insulationOfWallsOutsideThickness',
      required: false,
      type: FormFieldType.TextField,
    },

    {
      name: 'otherModernizations',
      required: false,
      type: FormFieldType.TextField,
    },
  ],
  id: StepId.MODERNIZATION,
};

export default config;
