























































import { PropType, computed, defineComponent, ref } from '@vue/composition-api';
import { StepDefinition } from '../interfaces/step-definition';
import { FormFieldType } from '../consts/input-types';
import HelperTextButton from './HelperTextButton.vue';
import GcCheckbox from './primitives/GcCheckbox.vue';
import GcSelect from './primitives/GcSelect.vue';
import GcSlider from './primitives/GcSlider.vue';
import GcTextField from './primitives/GcTextField.vue';
import MultipleChoice from './MultipleChoice.vue';
import GcAlert from './primitives/GcAlert.vue';
import { getValidators } from '../lib/forms/validators';
import { AssistantContext } from '../config/context';

export default defineComponent({
  components: {
    GcAlert,
    GcCheckbox,
    GcSelect,
    GcSlider,
    GcTextField,
    HelperTextButton,
    MultipleChoice,
  },
  props: {
    assistantContext: {
      default: () => ({}),
      type: Object as PropType<AssistantContext>,
    },
    fieldName: {
      required: true,
      type: String,
    },
    formData: {
      default: () => ({}),
      required: true,
      type: Object as PropType<any>,
    },
    hideLabel: {
      default: false,
      type: Boolean,
    },
    step: {
      default: () => ({}),
      type: Object as PropType<StepDefinition>,
    },
  },
  setup: function(props) {
    const helpVisible = ref(false);

    const field = computed(() =>
      props.step.fields?.find(f => f.name === props.fieldName),
    );
    const fieldLabelString = computed(
      () => `${props.step.id}.formFields.${props.fieldName}.label`,
    );

    if (!field) {
      console.error('Could not find form field ' + props.fieldName);
      return {
        controlComponent: null,
        controlValidators: [],
        field: {},
        fieldLabelString,
        helpVisible,
      };
    }

    const controlComponentMap: Record<number, string> = {
      [FormFieldType.TextField]: 'gc-text-field',
      [FormFieldType.MultipleChoice]: 'multiple-choice',
      [FormFieldType.Select]: 'gc-select',
      [FormFieldType.Slider]: 'gc-slider',
      [FormFieldType.Checkbox]: 'gc-checkbox',
    };
    const controlComponent = computed(
      () => controlComponentMap[field.value!.type],
    );

    const controlValidators = computed(() => getValidators(field.value!));

    return {
      FormFieldType,
      controlComponent,
      controlValidators,
      field,
      fieldLabelString,
      helpVisible,
    };
  },
});
