import { StepId } from '../../consts/assistant-steps';
import { StepDefinition } from '../../interfaces/step-definition';
import component from './Overview.vue';

const config: StepDefinition = {
  component,
  id: StepId.OVERVIEW,
};

export default config;
