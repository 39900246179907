import {
  ComputedRef,
  WritableComputedRef,
  computed,
} from '@vue/composition-api';
import { AssistantContext } from '../config/context';
import { StepGroupId } from '../consts/assistant-steps';
import { FormFieldType } from '../consts/input-types';
import { ContextPath, StepDefinition } from '../interfaces/step-definition';

function getStepContextDefaultValues(step: StepDefinition) {
  const values: any = {};
  step.fields?.forEach(field => {
    let defaultValue: any;

    switch (field.type) {
      case FormFieldType.MultipleChoice:
        defaultValue = [];
        break;
      default:
        defaultValue = '';
    }

    values[field.name] = defaultValue;
  });
  return values;
}

export function createContextFromSteps(steps: StepDefinition[]) {
  const context: AssistantContext = {};

  steps.forEach(step => {
    if (step.groupId) {
      if (!context[step.groupId]) {
        context[step.groupId] = [{}];
      }

      if (!context[`${step.groupId}Index`]) {
        context[`${step.groupId}Index`] = 0;
      }

      context[step.groupId][0][step.id] = getStepContextDefaultValues(step);
    } else {
      context[step.id] = getStepContextDefaultValues(step);
    }
  });

  return context;
}

export function addStepGroupItem(
  context: AssistantContext,
  groupId: StepGroupId | string,
  steps: StepDefinition[],
) {
  if (Array.isArray(context[groupId])) {
    const newGroupIndex = (context[groupId] as Array<any>).push({}) - 1;
    steps
      .filter(step => step.groupId && step.groupId === groupId)
      .forEach(step => {
        (context[groupId] as Array<any>)[newGroupIndex][
          step.id
        ] = getStepContextDefaultValues(step);
      });
    (context[`${groupId}Index`] as number) += 1;
  }
}

export function removeStepGroupItem(
  context: AssistantContext,
  groupId: StepGroupId | string,
  index: number,
) {
  (context[groupId] as Array<any>).splice(index, 1);
  (context[`${groupId}Index`] as number) -= 1;
}

export function setStepGroupIndex(
  context: AssistantContext,
  groupId: StepGroupId,
  index: number,
) {
  (context[`${groupId}Index`] as number) = index;
}

export function getStepGroupIndex(
  context: AssistantContext,
  groupId: StepGroupId | string,
): number {
  return context[`${groupId}Index`] as number;
}

export function getStepContext(
  context: AssistantContext,
  step: StepDefinition,
  repeatIndex?: number,
): ComputedRef {
  return computed(() => {
    if (step.groupId) {
      const groupRepeatIndex =
        repeatIndex !== undefined
          ? repeatIndex
          : context[`${step.groupId}Index`] || 0;

      if (!context[step.groupId]?.[groupRepeatIndex]?.[step.id]) {
        return null;
      }

      return context[step.groupId][groupRepeatIndex][step.id];
    } else {
      return context[step.id];
    }
  });
}

export function getStepsGroupContext(
  context: AssistantContext,
  groupId: StepGroupId,
) {
  return computed(() => {
    return context[groupId] || null;
  });
}

export function getContextValue(
  context: AssistantContext,
  path: ContextPath = [],
): WritableComputedRef<any> {
  const contextPathArray: Array<string | number> =
    typeof path === 'function' ? path(context) : path || [];

  return computed({
    get() {
      if (contextPathArray.length === 0) {
        return null;
      }

      let subpath: any = context;
      contextPathArray.forEach(key => {
        if (!subpath) {
          return null;
        }
        subpath = subpath[key];
      });

      return subpath;
    },
    set(value: any) {
      let obj: any = context;
      let i = 0;
      while (i < contextPathArray.length - 1) {
        obj = obj[contextPathArray[i]];
        i++;
      }
      obj[contextPathArray[i]] = value;
    },
  });
}
