













import { defineComponent, onMounted, ref } from '@vue/composition-api';

function scrollToTargetAdjusted() {
  const element = document.querySelector('.gc-current-step');
  const headerOffset = 90;

  if (element) {
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition - headerOffset;

    window.scrollBy({
      behavior: 'smooth',
      top: offsetPosition,
    });
  }
}

export default defineComponent({
  props: {
    label: {
      default: '',
      type: String,
    },
    valid: {
      default: true,
      type: Boolean,
    },
  },
  setup() {
    const scrollMarker = ref<null | HTMLElement>(null);

    onMounted(() => {
      setTimeout(() => {
        scrollToTargetAdjusted();
      }, 300);
    });

    return { scrollMarker };
  },
});
