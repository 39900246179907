export default {
  airConditioning: {
    formFields: {
      areasOfHouse: {
        label: 'Welche Bereiche des Hauses werden gekühlt / klimatisiert?',
      },
      ventilationType: {
        label: 'Wie lüften Sie?',
        options: {
          cooling: {
            label: 'Anlage zur Kühlung',
          },
          window: {
            label: 'Fensterlüftung',
          },
          withHeatRecovery: {
            label: 'Lüftungsanlage mit Wärmerückgewinnung',
          },
          withoutHeatRecovery: {
            label: 'Lüftungsanlage ohne Wärmerückgewinnung',
          },
        },
      },
    },
    title: 'Lüftung und Klimatisierung',
  },

  contactDetails: {
    formFields: {
      city: {
        label: 'Stadt',
      },
      email: {
        label: 'E-Mail',
      },
      firstName: {
        label: 'Vorname',
      },
      contactPersonTitle: {
        label: 'Anrede des Ansprechpartners',
      },
      isEstateAddressSame: {
        label:
          'Handelt es sich bei der oben genannten Adresse um die Immobilie zu der Sie eine Beratung wünschen?',
      },
      contactPersonFirstName: {
        label: 'Vorname des Ansprechpartners',
      },
      lastName: {
        label: 'Nachname',
      },
      contactPersonLastName: {
        label: 'Nachname des Ansprechpartners',
      },
      title: {
        label: 'Anrede'
      },
      estateCity: {
        label: 'Stadt der Immobilien',
      },
      street: {
        label: 'Straße/Hausnummer',
      },
      contactPersonEmail: {
        label: 'E-Mail des Ansprechpartners',
      },
      zipCode: {
        label: 'Postleitzahl'
      },
      contactPersonPhone: {
        label: 'Telefonnummer des Ansprechpartners',
      },
      estateStreet: {
        label: 'Straße/Hausnummer der Immobilie',
      },
      phone: {
        label: 'Telefonnummer'
      },
      estateZipCode: {
        label: 'Postleitzahl der Immobilie',
      },
      isContactPerson: {
        label: 'Gibt es einen Ansprechpartner vor Ort?'
      },
    },
    label: 'Bitte geben Sie Ihre Kontaktdaten an',
    title: 'Kontaktdaten',
  },

  done: {
    title: 'Fertig',
  },

  energySource: {
    formFields: {
      purpose: {
        label: 'Zu welchem Zweck?',
        options: {
          climate: {
            label: 'Klima',
          },
          heater: {
            label: 'Heizung',
          },
          hotWater: {
            label: 'Warmwasser',
          },
          lighting: {
            label: 'Beleuchtung',
          },
          ventilation: {
            label: 'Lüftung',
          },
        },
      },
      renewableEnergySources: {
        label: 'Nutzen Sie bereits regenerative Energieträger?',
        options: {
          pellet: {
            label: 'Pellets',
          },
          photovoltaic: {
            label: 'Photovoltaik',
          },
          solarThermalTechnology: {
            label: 'Solarthermie',
          },
          wood: {
            label: 'Holz',
          },
        },
      },
    },
    title: 'Regenerative Energieträger',
  },

  estate: {
    formFields: {
      accommodationUnitsNumber: {
        label: 'Anzahl Wohneinheiten',
      },
      adjacency: {
        label: 'Angrenzung',
        options: {
          freestanding: {
            label: 'freistehend'
          },
          attachedOnOneSide: {
            label: 'einseitig angebaut'
          },
          attachedOnSeveralSides: {
            label: 'mehrseitig angebaut'
          },
        }
      },
      buildingDescription: {
        label: 'Grundriss und Baubeschreibung',
        notion:
          'Falls der Grundriss nicht vorhanden sein sollte, können wir Ihnen diesen vor Ort gegen einen Aufpreis erstellen. Wenn vorhanden laden Sie bitte die Baubeschreibung hoch.',
      },
      commercialShare: {
        label: 'Gewerbeanteil (in Quadratmetern)',
        note: 'Aufgrund des Gewerbeanteils Ihrer Immobilie ist der Modernisierungs-Check (iSFP) für Sie leider nicht verfügbar.'
      },
      constructionYear: {
        label: 'Baujahr des Gebäudes',
      },
      livingSpace: {
        label: 'Gesamtwohnfläche (in Quadratmetern)',
      },
      type: {
        label: 'Gebäudetyp',
        options: {
          apartmentHouse: {
            label: 'Mehrfamilienhaus',
          },
          familyHouse: {
            label: 'Einfamilienhaus',
          },
          mediumTerracedHouse: {
            label: 'Reihenmittelhaus',
          },
          terracedHouse: {
            label: 'Reihenendhaus',
          },
        },
      },
    },
    label: 'Immobilie',
    title: 'Immobilie',
  },
  heatingSystem: {
    formFields: {
      additionalDocuments: {
        label:
          'Möchten Sie uns noch etwas in Bezug auf Ihre Heizungsanlage mitteilen?',
        note:
          'Dies können z.B. Angaben zu Hersteller, Typ, Baujahr und die Leistung Ihrer Heizungsanlage in kW sein.',
      },
      areaOfHeatedBasements: {
        label: 'Wie viele Quadratmeter des Kellers werden beheizt?',
      },
      fuelType: {
        label: 'Womit heizen Sie aktuell?',
        options: {
          biomass: {
            label: 'Biomasse',
          },
          districtHeating: {
            label: 'Fernwärme',
          },
          electricity: {
            label: 'Strom',
          },
          fuelOil: {
            label: 'Heizöl',
          },
          gas: {
            label: 'Erdgas',
          },
          pump: {
            label: 'Wärmepumpe',
          },
        },
      },
      heatingOfBasements: {
        label: 'Sind die Kellerräume beheizt?',
        options: {
          no: {
            label: 'nein',
          },
          yes: {
            label: 'ja',
          },
        },
      },
      heatingType: {
        label: 'Wie heizen Sie aktuell?',
        options: {
          centralHeating: {
            label: 'Zentralheizung',
          },
          floorHeating: {
            label: 'Etagenheizung',
          },
          singleOvens: {
            label: 'Einzelöfen',
          },
        },
      },
      systemDocuments: {
        label:
          'Bitte laden Sie hier relevante Dokumente zu Ihrer Heizungsanlage hoch.',
        note:
          'Dies können ein Foto des Typenschilds und/oder der letzte Schornsteinfegerbericht sein.',
      },
    },
    title: 'Heizungsanlage',
  },

  hotWater: {
    formFields: {
      additionalInfo: {
        label:
          'Möchten Sie uns noch etwas zu Ihren Warmwassererzeugern mitteilen? ',
        note:
          'Dies können z.B. Angaben zu Hersteller, Typ, Baujahr und die Leistung Ihrer Warmwassererzeuger in kW sein. Sollten Sie unterschiedliche Warmwassererzeuger nutzen, teilen Sie uns dies bitte hier mit.',
      },
      numberOfHotWaterGenerator: {
        label: 'Wie viele Warmwassererzeuger nutzen Sie?',
      },
      photosOfHotWaterGenerators: {
        label:
          'Bitte laden Sie hier Typenschilder oder Fotos Ihrer Warmwassererzeuger hoch.',
      },
      typeOfWaterHeating: {
        label: 'Wie erzeugen Sie Ihr Warmwasser?',
        options: {
          boile: {
            label: 'Boiler',
          },
          centralHeating: {
            label: 'Zentralheizung',
          },
          electricWaterHeater: {
            label: 'Strom-Durchlauferhitzer',
          },
          gasWaterHeater: {
            label: 'Gas-Durchlauferhitzer',
          },
        },
      },
    },
    title: 'Warmwasser',
  },

  modernization: {
    formFields: {
      basementСeilingInsulation: {
        label: 'Ist die Kellerdecke Ihres unbeheizten Kellers gedämmt?',
      },
      basementСeilingInsulationThickness: {
        label: 'Dicke der Dämmung',
      },
      upperFloorInsulation: {
        label: 'Ist die oberste Geschoßdecke bzw. das Dach gedämmt?',
      },
      basementСeilingInsulationYear: {
        label: 'Jahr',
      },
      upperFloorInsulationThickness: {
        label: 'Dicke der Dämmung',
      },
      insulationOfWallsOutside: {
        label: 'Sind die Außenwände der Immobilie gedämmt?',
      },
      windowInsulation: {
        label: 'Fenster mit Isolier- bzw. Wärmeschutzverglasung?',
      },
      insulationOfWallsOutsideThickness: {
        label: 'Dicke der Dämmung',
      },
      windowInsulationYear: {
        label: 'Jahr',
      },
      insulationOfWallsOutsideYear: {
        label: 'Jahr',
      },
      upperFloorInsulationYear: {
        label: 'Jahr',
      },
      otherModernizations: {
        label:
          'Haben Sie weitere Modernisierungsmaßnahmen durchgeführt oder möchten Sie uns uns noch etwas mitteilen?',
      },
    },
    note:
      'Bitte geben Sie für jede Modernisierungsmaßnahme die jeweiligen Werte ein. Sollten Sie bislang auf eine Maßnahme verzichtet haben, lassen Sie das Feld einfach frei.',
    title: 'Durchgeführte Modernisierungen',
  },

  number: {
    formFields: {
      customerNumber: {
        label: 'Kundennummer (falls vorhanden)',
      },
      orderNumber: {
        label: 'Bestellnummer (falls vorhanden)',
      },
    },
    title: 'Kundennummer',
  },

  overview: {
    label: 'Übersicht Ihrer Eingaben',
    title: 'Übersicht',
  },

  start: {
    buttonText: 'Beginnen',
    description:
      'Um Ihre Beratung so effizient wie möglich zu gestalten, wären wir Ihnen sehr verbunden, wenn Sie uns vorab einige Informationen zur Verfügung stellen.',
    label: 'Modernisierungs-Check-Assistent',
    title: 'Start',
  },
};
