import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import {
  FormMultipleChoiceConfig,
  StepDefinition,
} from '../../interfaces/step-definition';
import component from './EnergySource.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        columns: 2,
        enableCustomOption: true,
        options: [
          {
            value: 'pellet',
          },
          {
            value: 'wood',
          },
          {
            value: 'solarThermalTechnology',
          },
          {
            value: 'photovoltaic',
          },
        ],
        singleAnswer: false,
        verticalOptionLayout: false,
      } as FormMultipleChoiceConfig,
      name: 'renewableEnergySources',
      required: false,
      type: FormFieldType.MultipleChoice,
    },
    {
      config: {
        columns: 2,
        enableCustomOption: true,
        options: [
          {
            value: 'heater',
          },
          {
            value: 'ventilation',
          },
          {
            value: 'climate',
          },
          {
            value: 'hotWater',
          },
          {
            value: 'lighting',
          },
        ],
        singleAnswer: false,
        verticalOptionLayout: false,
      } as FormMultipleChoiceConfig,
      name: 'purpose',
      required: false,
      type: FormFieldType.MultipleChoice,
    },
  ],
  id: StepId.ENERGY_SOURCE,
};

export default config;
