



































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { stepComponentProps } from '../../lib/steps/helper';
import { useBaseTransitions } from '../../lib/xstate/transitions';
import { getStepContext } from '../../lib/context';
import AssistantStep from '../../components/AssistantStep.vue';
import AssistantStepNavigation from '../../components/AssistantStepNavigation.vue';
import GcForm from '../../components/primitives/GcForm.vue';
import GcTextArea from '../../components/primitives/GcTextArea.vue';
import GcDropzone from '../../components/primitives/GcDropzone.vue';
import FormField from '../../components/FormField.vue';
import { StepDefinition } from '../../interfaces/step-definition';
import { StepId } from '../../consts/assistant-steps';
import GcAlert from '../../components/primitives/GcAlert.vue';
import HelperTextButton from '../../components/HelperTextButton.vue';
import completedSteps from '../../lib/completed-steps';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcAlert,
    GcDropzone,
    GcForm,
    GcTextArea,
    HelperTextButton,
  },
  props: {
    ...stepComponentProps,
  },
  setup(props, { emit }) {
    const stepId = StepId.HEATING_SYSTEM;
    const { onNext, onPrev } = useBaseTransitions(emit);
    const formData = getStepContext(
      props.assistantContext,
      props.step as StepDefinition,
    );

    const systemDocuments = computed({
      get() {
        return props.assistantContext[stepId].systemDocuments;
      },
      set(files) {
        props.assistantContext[stepId].systemDocuments = files;
      },
    });

    const additionalDocuments = computed({
      get() {
        return props.assistantContext[stepId].additionalDocuments;
      },
      set(val) {
        props.assistantContext[stepId].additionalDocuments = val;
      },
    });

    const areaOfHeatedBasementsVisible = computed(() => {
      return props.assistantContext[stepId].heatingOfBasements[0] === 'yes';
    });

    const uploadDocumentsNoteVisible = ref(false);
    const moreDocumentsNoteVisible = ref(false);

    return {
      additionalDocuments,
      areaOfHeatedBasementsVisible,
      completedSteps,
      formData,
      moreDocumentsNoteVisible,
      onNext,
      onPrev,
      systemDocuments,
      uploadDocumentsNoteVisible,
    };
  },
});
