import { reactive, watch } from '@vue/composition-api';
import { StepId } from '../consts/assistant-steps';
import { efzkProd } from '../config/products';

const COMPLETED_STEPS_KEY = 'assistent-completed-steps-key';

// use local storage if env variable is set to "true"
const useLocalStorage = process.env.VUE_APP_USE_LOCALSTORAGE == 'true';

const getStorageKey = () => {
  return efzkProd.value
    ? `${COMPLETED_STEPS_KEY}-${efzkProd.value}`
    : COMPLETED_STEPS_KEY;
};

const stepsMap = {};
for (const id in StepId) {
  stepsMap[StepId[id]] = false;
}

const reactiveStepsMap = reactive(stepsMap);

watch(reactiveStepsMap, reactiveStepsMap => {
  if(useLocalStorage) {
    const storageKey = getStorageKey();
    localStorage.setItem(storageKey, JSON.stringify(reactiveStepsMap));
  }
});

export function restoreCompletedSteps() {
  if (useLocalStorage && localStorage) {
    const storageKey = getStorageKey();
    const stepsStr = localStorage.getItem(storageKey);
    if (stepsStr) {
      const storedSteps = JSON.parse(stepsStr);
      for (const key in storedSteps) {
        reactiveStepsMap[key] = storedSteps[key];
      }
    }
  }
}

export function removeCompletedSteps() {
  if(useLocalStorage) {
    const storageKey = getStorageKey();
    localStorage.removeItem(storageKey);
  }
}

export default reactiveStepsMap;
