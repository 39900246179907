import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import {
  FormMultipleChoiceConfig,
  StepDefinition,
} from '../../interfaces/step-definition';
import component from './HotWater.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        columns: 2,
        enableCustomOption: true,
        options: [
          {
            value: 'centralHeating',
          },
          {
            value: 'boile',
          },
          {
            value: 'electricWaterHeater',
          },
          {
            value: 'gasWaterHeater',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: false,
      } as FormMultipleChoiceConfig,
      name: 'typeOfWaterHeating',
      required: false,
      type: FormFieldType.MultipleChoice,
    },
    {
      name: 'numberOfHotWaterGenerator',
      required: false,
      type: FormFieldType.Slider,
    },
    {
      name: 'photosOfHotWaterGenerators',
      required: false,
      type: FormFieldType.FileUpload,
    },
    {
      name: 'additionalInfo',
      required: false,
      type: FormFieldType.TextField,
    },
  ],
  id: StepId.HOT_WATER,
};

export default config;
