import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import {
  FormMultipleChoiceConfig,
  StepDefinition,
} from '../../interfaces/step-definition';
import component from './AirConditioning.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        columns: 2,
        enableCustomOption: false,
        options: [
          {
            value: 'window',
          },
          {
            value: 'withHeatRecovery',
          },
          {
            value: 'withoutHeatRecovery',
          },
          {
            value: 'cooling',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: false,
      } as FormMultipleChoiceConfig,
      name: 'ventilationType',
      required: false,
      type: FormFieldType.MultipleChoice,
    },
    {
      name: 'areasOfHouse',
      required: false,
      type: FormFieldType.TextField,
    },
  ],
  id: StepId.AIR_CONDITIONING,
};

export default config;
