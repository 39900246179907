import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import {
  FormMultipleChoiceConfig,
  StepDefinition,
} from '../../interfaces/step-definition';
import component from './HeatingSystem.vue';
import { ValidationType } from '../../consts/validation-types';

const config: StepDefinition = {
  component,
  fields: [
    {
      config: {
        columns: 2,
        enableCustomOption: true,
        options: [
          {
            value: 'gas',
          },
          {
            value: 'biomass',
          },
          {
            value: 'pump',
          },
          {
            value: 'fuelOil',
          },
          {
            value: 'electricity',
          },
          {
            value: 'districtHeating',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: false,
      } as FormMultipleChoiceConfig,
      name: 'fuelType',
      required: false,
      type: FormFieldType.MultipleChoice,
    },
    {
      config: {
        columns: 2,
        options: [
          {
            value: 'centralHeating',
          },
          {
            value: 'singleOvens',
          },
          {
            value: 'floorHeating',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: false,
      } as FormMultipleChoiceConfig,
      name: 'heatingType',
      required: false,
      type: FormFieldType.MultipleChoice,
    },
    {
      name: 'systemDocuments',
      required: true,
      type: FormFieldType.FileUpload,
    },
    {
      name: 'additionalDocuments',
      required: false,
      type: FormFieldType.TextField,
    },
    {
      config: {
        columns: 2,
        options: [
          {
            value: 'yes',
          },
          {
            value: 'no',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: false,
      } as FormMultipleChoiceConfig,
      name: 'heatingOfBasements',
      required: false,
      type: FormFieldType.MultipleChoice,
    },
    {
      name: 'areaOfHeatedBasements',
      required: false,
      type: FormFieldType.TextField,
      validation: [ValidationType.numeric],
    },
  ],
  id: StepId.HEATING_SYSTEM,
};

export default config;
