































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import AssistantStep from '../../components/AssistantStep.vue';
import GcForm from '../../components/primitives/GcForm.vue';
import AssistantStepNavigation from '../../components/AssistantStepNavigation.vue';
import MultipleChoice from '../../components/MultipleChoice.vue';
import { getStepContext } from '../../lib/context';
import { stepComponentProps } from '../../lib/steps/helper';
import GcSlider from '../../components/primitives/GcSlider.vue';
import GcTextField from '../../components/primitives/GcTextField.vue';
import FormField from '../../components/FormField.vue';
import { useBaseTransitions } from '../../lib/xstate/transitions';
import GcDropzone from '../../components/primitives/GcDropzone.vue';
import { StepId } from '../../consts/assistant-steps';
import HelperTextButton from '../../components/HelperTextButton.vue';
import GcAlert from '../../components/primitives/GcAlert.vue';
import GcButton from '../../components/primitives/GcButton.vue';
import { JUMP_TO_CONTACTFORM } from '../../consts/assistant-state-events';
import GcDivider from '../../components/primitives/GcDivider.vue';
import completedSteps from '../../lib/completed-steps';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcAlert,
    GcButton,
    GcDivider,
    GcDropzone,
    GcForm,
    GcSlider,
    GcTextField,
    HelperTextButton,
    MultipleChoice,
  },
  props: {
    ...stepComponentProps,
  },
  setup(props, { emit }) {
    const { onNext, onPrev } = useBaseTransitions(emit);
    const formData = getStepContext(props.assistantContext, props.step!);
    const stepId = StepId.ESTATE;

    //completedSteps[props.step?.id] = false;

    const buildingDescription = computed({
      get() {
        return props.assistantContext[stepId].buildingDescription;
      },
      set(files) {
        props.assistantContext[stepId].buildingDescription = files;
      },
    });

    const commercialShareVisible = computed(() => {
      const { commercialShare, livingSpace } = props.assistantContext[stepId];
      return parseInt(commercialShare) > parseInt(livingSpace) / 2;
    });

    const uploadNoteVisible = ref(false);

    const goToContactDetails = () => {
      emit('transition', {
        event: JUMP_TO_CONTACTFORM,
      });
    };

    return {
      buildingDescription,
      commercialShareVisible,
      completedSteps,
      formData,
      goToContactDetails,
      onNext,
      onPrev,
      uploadNoteVisible,
    };
  },
});
