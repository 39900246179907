









import { defineComponent } from '@vue/composition-api';
import GcAlert from '../../components/primitives/GcAlert.vue';
import GcButton from '../../components/primitives/GcButton.vue';
import { stepComponentProps } from '../../lib/steps/helper';

export default defineComponent({
  components: {
    GcAlert,
    GcButton,
  },
  methods: {
    openSite() {
      window.open(location.origin, '_self');
    },
  },
  props: {
    ...stepComponentProps,
  },
});
