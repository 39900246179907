<script>
import { VCombobox } from 'vuetify/lib';
export default VCombobox.extend({
  props: {
    dense: {
      default: true,
      type: [Boolean, String],
    },
    outlined: {
      default: true,
      type: [Boolean, String],
    },
  },
});
</script>
