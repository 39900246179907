






















import { defineComponent } from '@vue/composition-api';
import AssistantStep from '../../components/AssistantStep.vue';
import OverviewListItem from '../../components/OverviewListItem.vue';
import AssistantStepNavigation from '../../components/AssistantStepNavigation.vue';
import { stepComponentProps } from '../../lib/steps/helper';
import { getOverviewItems, useDataSending } from './helper';
import md from '../../lib/formatter/markdown';
import { useBaseTransitions } from '../../lib/xstate/transitions';
import { StepId } from '../../consts/assistant-steps';
import completedSteps from '../../lib/completed-steps';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    OverviewListItem,
  },
  props: {
    ...stepComponentProps,
  },
  setup(props, { emit }) {
    const overviewItems = getOverviewItems(props.assistantContext);
    const onEditClick = (id: string) => {
      emit('transition', {
        event: 'BACK_TO_' + id.toUpperCase(),
      });
    };

    const { onNext, onPrev } = useBaseTransitions(emit);
    const { error, isSending, send } = useDataSending();
    const onSubmit = async () => {
      await send(overviewItems, props.assistantContext);
      onNext();
    };

    completedSteps[StepId.OVERVIEW] = true;

    return {
      error,
      isSending,
      md,
      onEditClick,
      onPrev,
      onSubmit,
      overviewItems,
    };
  },
});
