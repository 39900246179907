import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import {
  FormMultipleChoiceConfig,
  StepDefinition,
} from '../../interfaces/step-definition';
import component from './Estate.vue';
import { ValidationType } from '../../consts/validation-types';

const config: StepDefinition = {
  component,
  fields: [
    {
      name: 'constructionYear',
      required: true,
      type: FormFieldType.Slider,
    },
    {
      config: {
        columns: 2,
        options: [
          {
            value: 'familyHouse',
          },
          {
            value: 'apartmentHouse',
          },
          {
            value: 'terracedHouse',
          },
          {
            value: 'mediumTerracedHouse',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: false,
      } as FormMultipleChoiceConfig,
      name: 'type',
      required: false,
      type: FormFieldType.MultipleChoice,
    },
    {
      config: {
        columns: 2,
        options: [
          {
            value: 'freestanding',
          },
          {
            value: 'attachedOnOneSide',
          },
          {
            value: 'attachedOnSeveralSides',
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: false,
      } as FormMultipleChoiceConfig,
      name: 'adjacency',
      required: false,
      type: FormFieldType.MultipleChoice,
    },
    {
      name: 'accommodationUnitsNumber',
      required: true,
      type: FormFieldType.TextField,
      validation: [ValidationType.numeric],
    },
    {
      name: 'livingSpace',
      required: false,
      type: FormFieldType.TextField,
      validation: [ValidationType.numeric],
    },
    {
      name: 'commercialShare',
      required: false,
      type: FormFieldType.TextField,
      validation: [ValidationType.numeric],
    },
    {
      name: 'buildingDescription',
      required: false,
      type: FormFieldType.FileUpload,
    },
  ],
  id: StepId.ESTATE,
};

export default config;
