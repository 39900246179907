import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import { StepDefinition } from '../../interfaces/step-definition';
import component from './Number.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      name: 'customerNumber',
      required: false,
      type: FormFieldType.TextField,
    },
    {
      name: 'orderNumber',
      required: false,
      type: FormFieldType.TextField,
    },
  ],
  id: StepId.NUMBER,
};

export default config;
