// Title, First name, Last name, Address, Telephone, Mail
import { StepId } from '../../consts/assistant-steps';
import { FormFieldType } from '../../consts/input-types';
import {
  FormMultipleChoiceConfig,
  FormSelectConfig,
  StepDefinition,
} from '../../interfaces/step-definition';
import component from './ContactDetails.vue';

const config: StepDefinition = {
  component,
  fields: [
    {
      name: 'title',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'firstName',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'lastName',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'street',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'zipCode',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'city',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'email',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'phone',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'isEstateAddressSame',
      required: true,
      type: FormFieldType.Checkbox,
    },
    {
      name: 'estateStreet',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'estateZipCode',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'estateCity',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'isContactPerson',
      required: true,
      type: FormFieldType.Checkbox,
    },
    {
      name: 'contactPersonTitle',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'contactPersonFirstName',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'contactPersonLastName',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'contactPersonEmail',
      required: true,
      type: FormFieldType.TextField,
    },
    {
      name: 'contactPersonPhone',
      required: true,
      type: FormFieldType.TextField,
    },
  ],
  id: StepId.CONTACT_DETAILS,
  isContactForm: true,
};

export default config;
